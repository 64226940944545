import { useParams } from 'react-router';
import useAuth from './useAuth';

export default function useTester({ testerType }) {
  const { user } = useAuth();
  const { event } = useParams();

  const testerTypes = {
    showTicketTypes: {
      allowSuperAdmin: false,
      redirectPath: `/check-in/${event}/attendees`,
      testers: [
        'angie@boletia.com',
        'marcos@boletia.com',
        'david@boletia.com',
        'sergiocamacho+999@boletia.com',
      ],
    },
    showPaymentMethods: {
      allowSuperAdmin: true,
      redirectPath: `/dashboard/events/`,
      testers: [
        'angie@boletia.com',
        'marcos@boletia.com',
        'david@boletia.com',
        'sergiocamacho+999@boletia.com',
        'diego@boletia.com',
        'ignacio.valdes@boletia.com',
        'silvio@boletia.com',
      ],
    },
    showOldStatistics: {
      allowSuperAdmin: true,
      redirectPath: `/dashboard/events/`,
      testers: [],
    },
    showWallet: {
      allowSuperAdmin: true,
      redirectPath: `/dashboard/events/`,
      testers: [
        'sergiocamacho+999@boletia.com',
        'silvio@boletia.com',
        'david@boletia.com',
        'shirley@boletia.com',
      ],
    },
    showIsPrintable: {
      allowSuperAdmin: false,
      redirectPath: `/dashboard/events/`,
      testers: [
        'sergiocamacho+999@boletia.com',
        'david@boletia.com',
        'shirley@boletia.com',
        'marcos@boletia.com',
        'jhon.quirama@boletia.com',
        'aaron@boletia.com',
        'jyescas@boletia.com',
        'ian.rosas@boletia.com',
        'ricardo.palacios@boletia.com',
        'oswaldogamallo@gmail.com,',
        'maricarmen@boletia.com',
        'ignacio.valdes@boletia.com',
        'antonio.flores@boletia.com',
        'abraham@boletia.com',
        'oswaldogamallo@gmail.com',
      ],
    },
    showCampaigns: {
      allowSuperAdmin: false,
      redirectPath: '/dashboard/events',
      testers: [
        'sergiocamacho+999@boletia.com',
        'sergiocamacho@boletia.com',
        'david@boletia.com',
        'shirley@boletia.com',
        'angie@boletia.com',
        'marcos@boletia.com',
        'silvio@boletia.com',
        'richard.chi@boletia.com',
        'alejandro.arroyo@boletia.com',
        'julian.roldan@boletia.com',
        'shirley+10@boletia.com',
        'andrealarrauri@boletia.com',
        'ariadna.miranda@boletia.com',
        'desiree@boletia.com',
        'luis.ortega@boletia.com',
        'raul.benitez@boletia.com',
        'varinia@boletia.com',
        'daniel.tapia@boletia.com',
        'jfrancia@boletia.com',
        'johana.sepulveda@boletia.com',
        'manuel.ameneiros@boletia.com',
        'mariana@boletia.com',
        'angelferreira@boletia.com',
      ],
    },
    showActiveCampaigns: {
      allowSuperAdmin: false,
      redirectPath: '/dashboard/events',
      testers: [
        'sergiocamacho+999@boletia.com',
        'sergiocamacho@boletia.com',
        'david@boletia.com',
        'shirley@boletia.com',
        'angie@boletia.com',
        'marcos@boletia.com',
        'silvio@boletia.com',
        'richard.chi@boletia.com',
        'alejandro.arroyo@boletia.com',
        'julian.roldan@boletia.com',
        'shirley+10@boletia.com',
        'andrealarrauri@boletia.com',
        'ariadna.miranda@boletia.com',
        'desiree@boletia.com',
        'luis.ortega@boletia.com',
        'raul.benitez@boletia.com',
        'varinia@boletia.com>',
        'daniel.tapia@boletia.com',
        'jfrancia@boletia.com',
        'johana.sepulveda@boletia.com',
        'manuel.ameneiros@boletia.com',
        'mariana@boletia.com',
        'angelferreira@boletia.com',
      ],
    },
    showRsvp: {
      allowSuperAdmin: false,
      redirectPath: '/dashboard/events',
      testers: [
        'david@boletia.com',
        'shirley@boletia.com',
        'angie@boletia.com',
        'marcos@boletia.com',
        'silvio@boletia.com',
        'edwin.garcia@boletia.com',
        'ernesto.diaz@boletia.com',
        'sergiocamacho+999@boletia.com',
      ],
    },
  };

  const isTester = testerTypes[testerType]?.allowSuperAdmin
    ? testerTypes[testerType]?.testers.includes(user?.email) ||
      !!user?.super_admin
    : testerTypes[testerType]?.testers.includes(user?.email);

  const { redirectPath } = testerTypes[testerType] || {};

  return { isTester, redirectPath };
}
