/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { Grid } from '@mui/material';
import * as yup from 'yup';
import {
  FormSelect,
  FormItem,
  InputMask,
  FormText,
} from '@boletia/blt-components';
import {
  code,
  currency,
} from '../configurations/states';

let schema = {};

function OnBoardingGeneral({ values }) {
  schema = {
    phone_number: yup
      .string()
      .min(10, 'El teléfono debe tener como mínimo 10 dígitos')
      .max(12, 'El teléfono debe tener como máximo 12 dígitos')
      .required('Ingresa tu número de teléfono'),
    website: yup
      .string()
      .nullable()
      .transform((value) => (value === '' ? null : value))
      .matches(
        /^(https?:\/\/|www\.)[a-zA-Z0-9-_.]+\.[a-zA-Z]{2,}(\/\S*)?$/,
        'El link debe ser una URL válida'
      )
      .notRequired(),
    avg_capacity: yup.string().required('Debes seleccionar una opción'),
    avg_ticket_cost: yup.string().required('Ingrese un valor numérico'),
    country: yup.string().required('Debes seleccionar una opción'),
  };

  return (
    <Grid container spacing={2} sx={{ marginTop: '1px', marginBottom: '24px' }}>
      <Grid item xs={12}>
        <FormSelect name="country" label="País">
          <option hidden selected>
            Selecciona una opción
          </option>

          <option value="México">México</option>
          <option value="Estados Unidos">Estados Unidos</option>
          <option value="Colombia">Colombia</option>
          <option value="Guatemala">Guatemala</option>
          <option value="Argentina">Argentina</option>
          <option value="Chile">Chile</option>
          <option value="Perú">Perú</option>
          <option value="Otro">Otro</option>
        </FormSelect>
      </Grid>
      <Grid item xs={12}>
        <FormItem
          name="phone_number"
          label="Teléfono"
          labelDetail="(Opcional)"
          Component={InputMask}
          componentProps={{
            fullWidth: true,
            placeholder: 'Número de celular',
            label: 'Número de celular',
            maskProps: {
              thousandSeparator: false,
              isNumericString: true,
              prefix: code[values.country] ? `${code[values.country]} | ` : '',
              mask: ' ',
              format: code[values.country] 
                ? `${code[values.country]} | ### ### ####` 
                : '### ### ####',
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormText
          name="website"
          label="Link de página web o redes"
          labelDetail="(Opcional)"
          Component={InputMask}
          componentProps={{
            fullWidth: true,
            placeholder: 'Link de página web o redes',
            label: 'Link de página web o redes',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormSelect name="avg_capacity" label="Aforo">
          <option hidden selected>
            Selecciona una opción
          </option>
          <option value="1 - 250">Ente 1 - 250 personas</option>
          <option value="250 - 1000">Entre 250 - 1000 personas</option>
          <option value="1000 - 3000">Entre 1000 - 3000 personas</option>
          <option value="3000 - 6000">Entre 3000 - 6000 personas</option>
          <option value="6000+">Más 6000 personas</option>
        </FormSelect>
      </Grid>
      <Grid item xs={12}>
        <FormItem
          name="avg_ticket_cost"
          label="Costo promedio del evento"
          Component={InputMask}
          componentProps={{
            fullWidth: true,
            placeholder: 'Costo promedio del evento',
            label: 'Costo promedio del evento',
            maskProps: {
              thousandSeparator: true,
              isNumericString: false,
              mask: ' ',
              prefix: '$ ',
              suffix: currency[values.country] ? ` ${currency[values.country]}` : '',
              // allowEmptyFormatting: true,
            },
          }}
          sublabel="Escribe el costo promedio de tu evento"
        />
      </Grid>
    </Grid>
  );
}
export default OnBoardingGeneral;
export { schema };
