import { PropTypes } from 'prop-types';
import {
  FormItem,
  FormSelect,
  FormsWrapper,
  FormText,
} from '@boletia/blt-components/src/Forms';
import { InputMask } from '@boletia/blt-components';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import useUserClient from '../../../../hooks/useUserClient';
import AccountAlert from './AccountAlert';

export const mexStates = [
  'Aguascalientes',
  'Baja California',
  'Baja California Sur',
  'Campeche',
  'CDMX',
  'Chiapas',
  'Chihuahua',
  'Coahuila',
  'Colima',
  'Durango',
  'Estado de México',
  'Guanajuato',
  'Guerrero',
  'Hidalgo',
  'Jalisco',
  'Michoacán',
  'Morelos',
  'Nayarit',
  'Nuevo León',
  'Oaxaca',
  'Puebla',
  'Querétaro',
  'Quintana Roo',
  'San Luis Potosí',
  'Sinaloa',
  'Sonora',
  'Tabasco',
  'Tamaulipas',
  'Tlaxcala',
  'Veracruz',
  'Yucatán',
  'Zacatecas',
];

yup.setLocale({
  number: {
    min: 'El teléfono debe tener 10 dígitos',
    max: 'El teléfono debe tener 10 dígitos',
  },
});

const schema = yup.object({
  legal_representative_first_name: yup
    .string()
    .required('El nombre es requerido')
    .nullable(),
  legal_representative_last_name: yup
    .string()
    .required('Los apellidos son requeridos')
    .nullable(),
  fiscal_address_email: yup
    .string()
    .email('La dirección de correo electrónico debe ser válida')
    .trim()
    .required('Ingresa tu correo electrónico')
    .nullable(),
  phone: yup
    .string()
    .matches(/^([0-9]\d{9})/, 'El teléfono debe tener 10 dígitos')
    .min(10, 'El teléfono debe tener 10 dígitos')
    .max(12, 'El teléfono debe tener 12 dígitos')
    .required('el teléfono es requerido'),
  business_name: yup.string().required('Ingrese su razón social').nullable(),
  street: yup.string().required('Ingrese su calle').nullable(),
  number_ext: yup.string().required('Ingrese el número exterior').nullable(),
  suburb: yup.string().required('Ingrese su colonia').nullable(),
  state: yup.string().required('Seleccione el estado').nullable(),
  city: yup.string().required('Ingrese la ciudad').nullable(),
  cp: yup.string().typeError('No parece un código postal').required(),
  rfc: yup
    .string()
    .matches(
      /^[a-zA-Z&]{3,4}[0-9]{6}[a-zA-Z0-9]{3}$/,
      'Formato de RFC inválido',
    )
    .nullable()
    .required('El RFC es obligatorio'),
});

export default function FiscalForm({ value, onSubmit, hasRFC }) {
  // const [open, setOpen] = useState(false);
  const { client } = useUserClient();
  const formatValue = (d) => {
    const data = { ...d };
    if (d !== null && d !== undefined) {
      let radio = null;
      if (hasRFC) {
        data.rfc = hasRFC;
      }
      if (data.rfc !== null) {
        if (data.rfc.length === 12) radio = 'moral';
        if (data.rfc.length === 13) radio = 'fisica';
      }
      const newValues = {
        ...data,
        rfc_type: radio,
      };
      return { ...newValues };
    }
  };
  const transformRfcType = (v, setValue) => {
    if (
      v.length === 12 &&
      v.match(/^[a-zA-Z]{3,4}[0-9]{6,6}[a-zA-Z0-9]{3,3}$/)
    ) {
      setValue('rfc_type', 'moral');
    }
    if (
      v.length === 13 &&
      v.match(/^[a-zA-Z]{3,4}[0-9]{6,6}[a-zA-Z0-9]{3,3}$/)
    ) {
      setValue('rfc_type', 'fisica');
    }
    if (
      (v.length < 13 || v.length > 13) &&
      !v.match(/^[a-zA-Z]{3,4}[0-9]{6,6}[a-zA-Z0-9]{3,3}$/)
    ) {
      setValue('rfc_type', '');
    }
  };

  const formatData = (data) => {
    const copyData = { ...data };
    delete copyData.cp;
    copyData.cp = data.cp.toString();
    onSubmit(copyData);
  };

  const setDisable = () => {
    const { city, phone, rfc, state, street, suburb } = value;
    if (
      value?.business_name &&
      city &&
      value?.fiscal_address_email &&
      value?.legal_representative_first_name &&
      value?.legal_representative_last_name &&
      value?.number_ext &&
      phone &&
      rfc &&
      phone &&
      street &&
      state &&
      suburb
    ) {
      return true;
    }
    return false;
  };

  return (
    <FormsWrapper
      value={formatValue(value)}
      resolver={yupResolver(schema)}
      onSubmit={formatData}
    >
      <Grid item xs={12}>
        <AccountAlert open={setDisable()} />
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography>
            Proporciona tus datos para que podamos reembolsarte las ventas de
            tus eventos.
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormText
            name="legal_representative_first_name"
            label="Nombres"
            disabled={client || setDisable()}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormText
            name="legal_representative_last_name"
            label="Apellidos"
            disabled={client || setDisable()}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormText
            name="fiscal_address_email"
            label="Correo electrónico"
            disabled={client || setDisable()}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormItem
            name="phone"
            label="Teléfono"
            Component={InputMask}
            componentProps={{
              disabled: client || setDisable(),
              size: 'medium',
              label: 'Teléfono',
              fullWidth: true,
              maskProps: {
                thousandSeparator: true,
                isNumericString: false,
                mask: ' ',
                format: '+## (##) ####-####',
                allowEmptyFormatting: true,
              },
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormText
            name="business_name"
            label="Razón social"
            disabled={client || setDisable()}
          />
        </Grid>
        <Grid item md={6} xs={0} />
        <Grid item md={6} xs={12}>
          <FormText
            name="rfc"
            label="RFC, 12 caracteres para persona moral, 13 caracteres para persona física"
            transform={(v, data, setValue) => {
              const rfc = v.target.value;
              if (rfc) {
                transformRfcType(rfc, setValue);
              }
              return { ...v, target: { value: rfc.toUpperCase() } };
            }}
            disabled={hasRFC || client || setDisable()}
          />
        </Grid>
        <Grid item xs="12" md="6">
          <FormItem
            name="rfc_type"
            label="Tipo de RFC"
            Component={RadioGroup}
            componentProps={{
              disabled: client,
              row: 'row',
              sx: {
                borderRadius: '10px',
                border: 'solid 1px #DEDDDB',
                padding: '8px 16px',
                display: 'flex',
                gap: '28px',
              },
            }}
          >
            <FormControlLabel
              value="fisica"
              disabled
              control={<Radio />}
              label="Persona física"
            />
            <FormControlLabel
              value="moral"
              disabled
              control={<Radio />}
              label="Persona moral"
            />
          </FormItem>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">Dirección fiscal</Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormItem
            name="cp"
            label="Código postal"
            Component={InputMask}
            componentProps={{
              disabled: client || setDisable(),
              size: 'medium',
              fullWidth: true,
              label: 'Código postal',
              maskProps: {
                thousandSeparator: true,
                isNumericString: true,
                mask: '',
                format: '#####',
                allowEmptyFormatting: true,
              },
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormText
            name="street"
            label="Calles"
            disabled={client || setDisable()}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <FormText
            name="number_ext"
            label="Número exterior"
            disabled={client || setDisable()}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <FormText
            name="number_int"
            label="Número interior"
            disabled={client || setDisable()}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormText
            name="suburb"
            label="Colonia"
            disabled={client || setDisable()}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormText
            name="city"
            label="Ciudad"
            disabled={client || setDisable()}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormSelect
            name="state"
            label="Estado"
            disabled={client || setDisable()}
          >
            <option hidden selected>
              Selecciona una opción
            </option>
            {mexStates.map((state) => (
              <option value={state}>{state}</option>
            ))}
          </FormSelect>
        </Grid>
        {!client && (
          <Grid
            item
            sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{ width: { xs: '100%', md: 'auto' } }}
              size="medium"
              disabled={client || setDisable()}
            >
              Guardar Cambios
            </Button>
          </Grid>
        )}
      </Grid>
    </FormsWrapper>
  );
}

FiscalForm.propTypes = {
  value: PropTypes.object,
  onSubmit: PropTypes.func,
  hasRFC: PropTypes.string,
};
